<template style="color:blue">
    <exclamation-circle-filled v-if="name == 'exclamation-circle-filled'" />
    <desktop-outlined v-else-if="name == 'desktop-outlined'" />
    <LongPants v-else-if="name == 'long-pants'" style="fill: blue;"/>
    <disconnect-outlined  v-else-if="name == 'disconnect-outlined'"/>
    <setting-outlined v-else-if="name == 'setting-outlined'" />
    <api-outlined v-else-if="name == 'api-outlined'" />
    <delete-filled v-else-if="name == 'delete-filled'" />
    <left-outlined v-else-if="name == 'left-outlined'" />
    <user-outlined v-if="name == 'user-outlined'" />
    <heart-filled v-if="name == 'heart-filled'" />
    <whats-app-outlined v-if="name == 'whats-app-outlined'" />
    <book-outlined v-if="name == 'book-outlined'" />
    <exception-outlined v-if="name == 'exception-outlined'" />
    <logout-outlined v-if="name == 'logout-outlined'" />
    <close-outlined v-if="name == 'close-outlined'"/>
    <close-circle-outlined :style="style" v-if="name == 'close-circle-outlined'"/>
    <menu-outlined v-if="name == 'menu-outlined'"/>
    <home-filled v-if="name == 'home-filled'"/>
    <idcard-filled v-if="name == 'idcard-filled'"/>
    <question-circle-filled v-if="name == 'question-circle-filled'"/>
    <shop-filled v-if="name == 'shop-filled'"/>
    <exclamation-circle-outlined :style="style" v-if="name == 'exclamation-circle-outlined'"/>
    <check-circle-filled :style="style" v-if="name == 'check-circle-filled'" />
    <check-circle-outlined :style="style" v-if="name == 'check-circle-outlined'" />
    <question-circle-outlined :style="style" v-if="name == 'question-circle-outlined'" />
    <down-outlined  :style="style" v-if="name == 'down-outlined'" />
    <up-outlined  :style="style" v-if="name == 'up-outlined'" />
    <wallet-outlined  :style="style" v-if="name == 'wallet-outlined'" />
    <caret-right-outlined v-if="name == 'caret-right-outlined'" :rotate="rotate" />
    <printer-outlined v-if="name == 'printer-outlined'" />
    <dollar-circle-outlined v-if="name == 'dollar-circle-outlined'" />
    <save-outlined v-if="name == 'save-outlined'"  />
</template>
 
<script>

import {
    PrinterOutlined,SaveOutlined,
    ExclamationCircleOutlined,CheckCircleOutlined,QuestionCircleOutlined,DownOutlined,UpOutlined,CaretRightOutlined,
    DisconnectOutlined,DesktopOutlined,CloseCircleOutlined,
    LogoutOutlined, ExceptionOutlined, BookOutlined, WhatsAppOutlined, UserOutlined, HomeFilled, IdcardFilled, ShopFilled, QuestionCircleFilled, MenuOutlined, CloseOutlined, HeartFilled,
    LeftOutlined, DeleteFilled, SettingOutlined, CheckCircleFilled, ExclamationCircleFilled, ApiOutlined,WalletOutlined,DollarCircleOutlined
} from '@ant-design/icons-vue';
import LongPants from '@/components/ico/LongPants.vue';

export default ({
    name: 'MyIcon',
    props: {
        name: String,
        style: String,
        class: String,
        rotate: Number,
    },
    components: {
        ExclamationCircleOutlined,CheckCircleOutlined,QuestionCircleOutlined,DownOutlined,UpOutlined,CaretRightOutlined,
        LongPants,DisconnectOutlined,DesktopOutlined,CloseCircleOutlined,PrinterOutlined,SaveOutlined,
        LogoutOutlined, ExceptionOutlined, BookOutlined, WhatsAppOutlined, UserOutlined, HomeFilled, IdcardFilled, ShopFilled, QuestionCircleFilled, MenuOutlined, CloseOutlined, HeartFilled,
        LeftOutlined, DeleteFilled, SettingOutlined, CheckCircleFilled, ExclamationCircleFilled, ApiOutlined, DollarCircleOutlined,WalletOutlined
    },
})
</script>
