export default {
  listGive: [
    {
        title: "Air Mineral",
        subTitle: "4 point",
        img: "https://png.pngtree.com/png-vector/20220701/ourmid/pngtree-mineral-water-bottle-vector-illstration-png-image_5626453.png",
        point: 4,
        price: 4000,
        productOwner: "surya"
      },
      {
        title: "Jus",
        subTitle: "12 point",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpmQHK05Xrna1dtMbPLVtHqJWrv_6kYFTRUQ&s",
        point: 12,
        price: 12000,
        productOwner: "mami"
      },
      {
        title: "Es teh / kopi",
        subTitle: "6 point",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH-Tfy6TK4ammrdETzB02ZuHQafM30J1uJCw&s",
        point: 6,
        price: 6000,
        productOwner: "mami"
      },
      {
        title: "Sarung tangan billiard",
        subTitle: "20 point",
        img: "https://st2.depositphotos.com/5007459/8560/v/450/depositphotos_85600010-stock-illustration-rubber-gloves.jpg",
        point: 20,
        price: 25000,
        productOwner: "surya"
      },
      {
        title: "Gratis Main 1 jam",
        subTitle: "28 point",
        img: "https://i.pinimg.com/474x/7a/3e/3a/7a3e3a252b578607556f997fed72f396.jpg",
        point: 28,
        price: 25000,
        productOwner: ""
      },
      {
        title: "Baju Kaus",
        subTitle: "35 point",
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhcHDRJ0uzEwkisAVF6tnLqTBfk5a7B9oeYg&s",
        point: 35,
        price: 35000,
        productOwner: "helfin"
      },
      {
        title: "Stik Billiard",
        subTitle: "180 point",
        img: "https://i.etsystatic.com/17942801/r/il/5de5d9/1727802144/il_1080xN.1727802144_9iy9.jpg",
        point: 180,
        price: 500000,
        productOwner: ""
      },
      {
        title: "Topi",
        subTitle: "180 point",
        img: "https://cdn2.iconfinder.com/data/icons/hat-outline/48/topi_3-512.png",
        point: 180,
        price: 150000,
        productOwner: ""
      }
    ],
    costOptionsChargeTo:[
      {
        id:"atika",
        label:"Meja 1,2,3"
      },
      {
        id:"surya",
        label:"Meja 4,5"
      },
      {
        id:"surya",
        label:"surya"
      },
      {
        id:"all",
        label:"Bersama"
      }
    ],
    Logo : "@/assets/images/logo.png",
    KeyLocStoreUserIdentity :"user-identity",
    KeyLocStoreLoginSession :"login-session",
    KeyLocStoreForgetPass :"tmp-89h230j@k",
    KeyLocStoreRegister :"tmp-9ij208h@k",
    IconOptions: [
        {
          value: 'long-pants',
          label: 'long-pants',
          icon: 'long-pants',
        },
        {
          value: 'disconnect-outlined',
          label: 'disconnect-outlined',
          icon: 'disconnect-outlined',
        },
      ],
      ConditionOptions:[
        {
            value: '>',
            label: '>',
          },
          {
            value: '>=',
            label: '>=',
          },
          {
            value: '<=',
            label: '<=',
          },
          {
            value: '<',
            label: '<',
          },
          {
            value: '=',
            label: '=',
          },
      ],
      UnitTypeOptions: [
        {
          value: 'PCS',
          label: '/PCS (Pieces)',
        },
        {
          value: 'KG',
          label: '/KG (Kilogram)',
        },
        {
          value: 'DUS',
          label: '/DUS (dus)',
        },
        {
          value: 'KAR',
          label: '/KAR (Karung)',
        },
        {
          value: 'MTR',
          label: '/MTR (Meter)',
        },
        {
          value: 'LTR',
          label: 'LTR (liter)',
        },
      ],
}