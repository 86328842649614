<template>
    <div v-if="activeMenu != 'hide'">
        <div id="navMenu" class="navbar-bottom menu-list" :class="{ 'nav-open': this.$store.state.showMenu }"
            style="background:linear-gradient(rgb(31, 233, 237), rgb(3, 83, 84));">
            <div style="height: 120%;">
                <div class="close-menu">
                    <button class="btn-close" v-on:click="menuShow()">
                    </button>
                </div>
                <div style="height: 300px;overflow-y: hidden;color: #ffffff;font-size: 12px;">
                    <div  :onClick="()=>{this.$router.push(m.link);}" v-for="(m, i) of listMenu" :key="i" class="coll ln-b cursor-pointer" :class="{ 'activeMenu': activeMenu == m.key }">
                        <div class="coll-3 txt-c">
                            <a-space>
                                <MyIcon name="idcard-filled" v-if="m.key=='employe'"  style="font-size: 14px;"/>
                                <MyIcon name="user-outlined" v-if="m.key=='profile'" style="border: 1px solid aliceblue;border-radius: 50%;font-size: 14px;" />
                                <MyIcon name="heart-filled" v-if="m.key=='subcription'" style="font-size: 14px;" />
                                <MyIcon name="whats-app-outlined" v-if="m.key=='whatsapp-autosender'" style="font-size: 14px;" />
                                <MyIcon name="book-outlined" v-if="m.key=='financial-book'" style="font-size: 14px;" />
                                <MyIcon name="exception-outlined" v-if="m.key=='tac'" style="font-size: 14px;" />
                                <MyIcon name="logout-outlined" v-if="m.key=='logout'" style="font-size: 14px;" />
                            </a-space>
                        </div>
                        <div class="coll-8">
                            <label>{{ m.label }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="navbar-bottom fixed-bottom " style="overflow: visible;">
            <div class="menu-bar">
                    <div class="menu-bar-button" v-on:click="menuShow()">
                        <div v-if="this.$store.state.showMenu" style="font-size: 30px;
                            bottom: 7px;
                            position: absolute;">
                            <a-space>
                                <MyIcon name="close-outlined" />
                            </a-space>
                        </div>
                        <div v-else>
                            <a-space>
                                <MyIcon name="menu-outlined" />
                            </a-space>
                        </div>
                    </div>
                <p class="button-footer-text menu">Menu</p>
            </div>
            <div class="menu_bottom button-footer-1">
                <router-link to="/business">
                    <div align="center">
                        <a-space>
                            <div class="fa_house" :class="{ 'active': activeMenu == 'home' }">
                                <MyIcon name="home-filled" />
                            </div>
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'home' }">Beranda</p>
                    </div>
                </router-link>
            </div>
            <div class="menu_bottom button-footer-2">
                <router-link to="/business/casier">
                    <div align="center">
                        <a-space class="wallet_dompet" :class="{ 'active': activeMenu == 'casier' }">
                            <MyIcon name="desktop-outlined" />
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'casier' }">Kasir</p>
                    </div>
                </router-link>
            </div>
            <div class="menu_bottom button-footer-3">
                <a href="">
                    <div align="center">
                        <a-space  class="lines" :class="{ 'active': activeMenu == 'help' }">
                            <MyIcon name="question-circle-filled"/>
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'help' }">Bantuan</p>
                    </div>
                </a>
            </div>
            <div class="menu_bottom button-footer-4">
                <router-link to="/business/point">
                    <div align="center">
                        <a-space class="i_shop" :class="{ 'active': activeMenu == 'point' }">
                            <MyIcon name="dollar-circle-outlined" />
                        </a-space>
                        <p class="button-footer-text" :class="{ 'active': activeMenu == 'point' }">Point</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
  
<style scoped src="@/assets/styles/mystyles.css"></style>
<script>
import MyIcon from '@/components/MyIcon.vue';

export default {
    name: 'MenuFooter',
    props: {
        activeMenu:Object
    },
    components: {
        MyIcon
    },
    data() {
        return {
            listMenu: [
                {
                    "label": "Profile Saya",
                    "key": "profile",
                    "link": "/business/profile"
                },
                {
                    "label": "Karyawan Saya",
                    "key": "employe",
                    "link": "/business/employe"
                },
                {
                    "label": "Subcription Saya",
                    "key": "subcription",
                    "link": "/business/profile"
                },
                {
                    "label": "Aktifkan Whatsapp Autosender",
                    "key": "whatsapp-autosender",
                    "link": "/business/profile"
                },
                {
                    "label": "Buku Keuangan Saya",
                    "key": "financial-book",
                    "link": "/business/profile"
                },
                {
                    "label": "Syarat & Ketentuan Berlaku",
                    "key": "tac",
                    "link": "/business/profile"
                },
                {
                    "label": "Keluar Dari Akun",
                    "key": "logout",
                    "link": "/business/logout"
                },
            ]
        }
    },
    methods: {
        menuShow: function () {
            this.$store.commit("setShowMenu",!this.$store.state.showMenu);
        }
    },
    mounted() {
    }
}
</script>
  