import { createRouter, createWebHistory } from 'vue-router';
import store from './store.js'
import constants from '@/constants';

const logout = (to, from, next) => {
    next('/business/login')
}

const beforeEnter = (to, from, next) => {
    const condition = false
    if (condition) {
        next('/business/login')
    } else {
        next()
    }
}

const mustLogin = (to, from, next) => {
    const token = localStorage.getItem(constants.KeyLocStoreLoginSession) !== undefined ? localStorage.getItem(constants.KeyLocStoreLoginSession) : '';
    if (token == '' || token == null) {
        next('/business/login')
    } else {
        next()
    }
}

const menu = (menu = String, show = true) => {
    return {
        name: menu,
        show: show
    }
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            component: () => import('./view/app/business/HomeClient.vue'),
            meta: { menu: menu("hide") },
        },
        {
            path: '/business',
            component: () => import('./view/app/business/HomeApp.vue'),
            meta: { menu: menu("home") },
            beforeEnter: mustLogin,
        },
        {
            path: '/business/forget-password',
            component: () => import('./view/app/business/ForgetPassword.vue'),
            beforeEnter: beforeEnter
        },
        {
            path: '/business/casier',
            component: () => import('./view/app/business/Casier.vue'),
            meta: { menu: menu("casier") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/point',
            component: () => import('./view/app/business/Point.vue'),
            meta: { menu: menu("point") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/point/detail/:id',
            component: () => import('./view/app/business/OutletDetail.vue'),
            meta: { menu: menu("point") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/point/main-services/:outlet_id',
            component: () => import('./view/app/business/OutletMainServices.vue'),
            meta: { menu: menu("point") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/point/additional-services',
            component: () => import('./view/app/business/OutletAdditionalServices.vue'),
            meta: { menu: menu("point") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/point/add',
            component: () => import('./view/app/business/OutletAdd.vue'),
            meta: { menu: menu("point") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/point/update',
            component: () => import('./view/app/business/OutletUpdate.vue'),
            meta: { menu: menu("point") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/employe',
            component: () => import('./view/app/business/Employe.vue'),
            meta: { menu: menu("employe") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/profile',
            component: () => import('./view/app/business/Profile.vue'),
            meta: { menu: menu("profile") },
            beforeEnter: beforeEnter
        },
        {
            path: '/business/login',
            component: () => import('./view/app/business/Login.vue'),
            beforeEnter: beforeEnter
        },
        {
            path: '/business/register',
            component: () => import('./view/app/business/Register.vue'),
            beforeEnter: beforeEnter
        },
        {
            path: '/business/logout',
            component: () => "",
            beforeEnter: logout
        },
        {
            path: "/:pathMatch(.*)*",
            component: () => import('./PageNotFound.vue')
        }
    ]
});

router.beforeEach(() => {
    store.commit("setLoading",true)
});

router.afterEach(() => {
    store.commit("setShowMenu",false);
    store.commit("setLoading",false)
});

export default router