import Constant from '@/constants.js';

const canAccess = (roles) => {
    let user = JSON.parse(localStorage.getItem(Constant.KeyLocStoreUserIdentity))
    if(user && user.role != undefined){
        return roles.includes(user.role)
    }
    return false
}

const getUser = () => {
    let user = JSON.parse(localStorage.getItem(Constant.KeyLocStoreUserIdentity))
    if(user && user.role != undefined){
        return user
    }
    return {}
}

const common = {
    canAccess,
    getUser
}

export default common