const mooney = (value, fixed = 0) => {
    let val = (value/1).toFixed(fixed).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
const dateTime = (dateTimeString) => {
  const t = new Date(dateTimeString);
return t.getDate() + '/' +(t.getMonth() + 1) +"/"+t.getFullYear()+" "+t.getHours()+":"+t.getMinutes();
}

const formatString = {
  dateTime,
  mooney
}

export default formatString